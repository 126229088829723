import $ from 'jquery';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import tabby from 'tabby';
import wowjs from 'wowjs';
import headroomJS from "headroom.js";
import bootstrap from "bootstrap";

import Hiraku from './modules/hiraku';
import addBrowserClass from './modules/browser';
import fontSetting from './modules/font';
import Tipped from './modules/tipped';
// import drawerNav from './modules/drawerNav';


// -------------------------------------------------------------------
// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// slick-carousel JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// $(".p-profBody__Photo-wrapper").slick({
//     // normal options...
//     infinite: true,
//     prevArrow: '<div class="p-profBody__Photo-prev p-profBody__Photo-icon"><i class="fas fa-arrow-alt-circle-left"></i></div>',
//     nextArrow: '<div class="p-profBody__Photo-next p-profBody__Photo-icon"><i class="fas fa-arrow-alt-circle-right"></i></div>',
//     speed: 500,
//     dots: true,
//     dotsClass: 'p-profBody__Photo-dots'
// });

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// tabby JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
tabby.init({
    selectorToggle: '[data-tab]', // Tab toggle selector
    selectorToggleGroup: '[data-tabs]', // Tab toggle group selector
    selectorContent: '[data-tabs-pane]', // Tab content selector
    selectorContentGroup: '[data-tabs-content]', // Tab content group selector
    toggleActiveClass: 'is-active', // Class added to active toggle elements
    contentActiveClass: 'is-active', // Class added to active tab content areas
    initClass: 'js-tabby', // Class added to <html> element when initiated
    stopVideo: true, // [Boolean] If true, stop videos when tab closes
    callback: function(tabs, toggle) {} // Function that's run after tab content is toggled
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]');

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// headroom JS
// -------------------------------------------------------------------
const header = document.querySelector('header');
let headroom = new headroomJS(header, {
    //上から120pxより下の領域で10px以上スクロールするとイベントが発動する
    "offset": 100,
    "tolerance": 10,
    "classes": {
        "initial": "initial"
    }
});
headroom.init();

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// WOWJS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
window.WOW = require('wowjs').WOW;
new WOW({
    mobile: false
}).init();


// -------------------------------------------------------------------
// -------------------------------------------------------------------
// HIRAKU JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// new Hiraku(".js-menuBody", {
//     btn: ".js-menuTrigger",
//     fixedHeader: ".l-header",
//     direction: "right",
//     width: '300px'
// });
// new Hiraku(".js-menuBody2", {
//     btn: ".js-menuTrigger2",
//     fixedHeader: ".l-header",
//     direction: "right",
//     width: '300px'
// });

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// jQuery
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// $(function() {
//     // プルダウン変更時に遷移
//     $('select[name=selectorArchives]').change(function() {
//         if ($(this).val() != '') {
//             window.location.href = $(this).val();
//         }
//     });
//     $(".l-header__Trigger").fancybox({
//         iframe: {
//             css: {
//                 width: '600px'
//             }
//         }
//     });
// });


// -------------------------------------------------------------------
// -------------------------------------------------------------------
// Tipped
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// $(window).on('load', function() {
//     if ($('.is-switchUser').hasClass('is-active')) {
//         $('.is-switchJinji').removeClass('is-active');
//         $('.is-switchUser').addClass('is-active');
//         $('.c-switch').addClass('is-activeUser');
//         $('.c-switch').removeClass('is-activeJinji');
//     } else {
//         $('.is-switchJinji').addClass('is-active');
//         $('.is-switchUser').removeClass('is-active');
//         $('.c-switch').addClass('is-activeJinji');
//         $('.c-switch').removeClass('is-activeUser');
//     }
// });

$(document).ready(function($) {
    var parPosition = [];
    $('.is-section').each(function() {
        parPosition.push($(this).offset().top);
    });
    $(document).scroll(function() {
        var position = $(document).scrollTop(),index;
        for (var i = 0; i < parPosition.length; i++) {
            if (position <= parPosition[i]) {
                index = i;
                break;
            }
        }
        $('.c-dotNav-item').removeClass('is-active');
        $('.c-dotNav-item:eq(' + index + ')').addClass('is-active');
    });
    $('.c-dotNav-item').click(function() {
        $('.c-dotNav-item').removeClass('is-active');
        $(this).addClass('is-active');
    });
});


$(function() {
    // var clickEventType = ((window.ontouchstart !== null) ? 'click' : 'touchstart');
    // $('.is-switchUser').on(clickEventType, function() {
    //     $('.is-switchJinji').removeClass('is-active');
    //     $('.is-switchUser').addClass('is-active');
    //     $('.c-switch').addClass('is-activeUser');
    //     $('.c-switch').removeClass('is-activeJinji');
    // });
    // $('.is-switchJinji').on(clickEventType, function() {
    //     $('.is-switchJinji').addClass('is-active');
    //     $('.is-switchUser').removeClass('is-active');
    //     $('.c-switch').addClass('is-activeJinji');
    //     $('.c-switch').removeClass('is-activeUser');
    // });

    $(".js-menuBody").hiraku({
      btn: ".js-menuTrigger",
      fixedHeader: ".l-header",
      direction: "right"
    });

    Tipped.create(".js-tooltip-1", "", {
        skin: 'CCP',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-2", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-3", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-4", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-5", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-6", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-7", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-8", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-9", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-10", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-11", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-12", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
    Tipped.create(".js-tooltip-13", "", {
        skin: 'CCR',
        position: 'top',
        onShow: function(content, element) {
            $(element).addClass('is-active');
        },
        afterHide: function(content, element) {
            $(element).removeClass('is-active');
        }
    });
});
